// Or loading states:
export const ORG_DETAILS_LOADING = 'orgs/ORG_DETAILS_LOADING';
export const ORG_DETAILS_LOADED = 'orgs/ORG_DETAILS_LOADED';
const ORG_DETAILS_LOAD_ERROR = 'orgs/ORG_DETAILS_LOAD_ERROR';

// Page load and clear actions
const CLEAR_ARCHIVED_CLASSES_SELECTION = 'organisationPage/CLEAR_ARCHIVED_CLASSES_SELECTION';
// const CLEAR_ARCHIVED_ORGS_SELECTION = 'organisationPage/CLEAR_ARCHIVED_ORGS_SELECTION';
const CLEAR_USER_SELECTION = 'organisationPage/CLEAR_USER_SELECTION';

// Update list selection actions
const SET_STUDENT_SELECTED = 'organisationPage/SET_STUDENT_SELECTED';
const SET_TEACHER_SELECTED = 'organisationPage/SET_TEACHER_SELECTED';
const SET_CLASSROOM_SELECTED = 'organisationPage/SET_CLASSROOM_SELECTED';
const CHECK_IF_ALL_STUDENTS_ARE_SELECTED = 'classroomPage/CHECK_IF_ALL_STUDENTS_ARE_SELECTED';
const SET_ALL_STUDENTS_ARE_SELECTED = 'organisationPage/SET_ALL_STUDENTS_ARE_SELECTED';
const SET_ALL_TEACHERS_ARE_SELECTED = 'organisationPage/SET_ALL_TEACHERS_ARE_SELECTED';
const CHECK_IF_ALL_TEACHERS_ARE_SELECTED = 'organisationPage/CHECK_IF_ALL_TEACHERS_ARE_SELECTED';
const SET_SELECT_ALL_STUDENTS = 'classroomPage/SET_SELECT_ALL_STUDENTS';
const SET_SELECT_ALL_TEACHERS = 'organisationPage/SET_SELECT_ALL_TEACHERS';

export const ORG_LOADED = 'organisationPage/ORG_LOADED';

// Cancel user edit actions
const SHOW_MODAL = 'organisationPage/SHOW_MODAL';

const initialState = {
  orgId: null,
  loading: true,
  errorLoading: false,

  // Init tabs for Students, Teachers, Classes
  selectedStudentIds: [],
  selectedTeacherIds: [],
  selectedClassroomIds: [],
  areTeachersSelected: false,
  areStudentsSelected: false,
  isLmsLtiTool: null
};

function addOrRemove(selectedIds, id, isSelected) {
  let selIds = [];
  if (isSelected) {
    if (Array.isArray(id)) {
      selIds = [...selectedIds, ...id];
    } else {
      selIds = [...selectedIds, id];
    }
  } else if (Array.isArray(id)) {
    selIds = selectedIds.filter(sId => id.indexOf(sId) === -1);
  } else {
    selIds = selectedIds.filter(sId => id !== sId);
  }
  return selIds;
}

let cachedSelectedIds = [];

export default function organisationPage(state = initialState, action = {}) {
  switch (action.type) {
    case ORG_DETAILS_LOADING:
      return {
        ...initialState,
        orgId: action.orgId
      };

    case ORG_DETAILS_LOAD_ERROR:
      return {
        ...state,
        loading: false,
        errorLoading: true
      };

    case ORG_DETAILS_LOADED:
      return {
        ...state,
        loading: false,
        errorLoading: false,
        isLmsLtiTool: action.orgData.lmsLtiTools !== undefined && action.orgData.lmsLtiTools !== null
      };

    case CLEAR_ARCHIVED_CLASSES_SELECTION:
      return {
        ...state,
        selectedClassroomIds: state.selectedClassroomIds.filter(id => !action.classroomIds.includes(id))
      };

    case CLEAR_USER_SELECTION:
      return {
        ...state,
        selectedStudentIds: [],
        selectedTeacherIds: [],
        selectedClassroomIds: []
      };

    // Not applicable while multiple org-selection is out of scope:
    // case CLEAR_ARCHIVED_ORGS_SELECTION:
    //   return {
    //     ...state,
    //     selectedOrgIds: state.selectedOrgIds.filter(id => !action.failedIds.includes(id))
    //   };

    // Update list selection actions
    case SET_STUDENT_SELECTED:
      return {
        ...state,
        selectedStudentIds: addOrRemove(state.selectedStudentIds, action.studentId, action.isSelected)
      };
    case SET_TEACHER_SELECTED:
      return {
        ...state,
        selectedTeacherIds: addOrRemove(state.selectedTeacherIds, action.teacherId, action.isSelected)
      };
    case SET_CLASSROOM_SELECTED:
      return {
        ...state,
        selectedClassroomIds: addOrRemove(state.selectedClassroomIds, action.classroomId, action.isSelected)
      };
    case SHOW_MODAL:
      return {
        ...state,
        showModal: action.showModal
      };
    case CHECK_IF_ALL_STUDENTS_ARE_SELECTED:
      if (state.selectedStudentIds.length === action.studentIds.length) {
        return {
          ...state,
          areStudentsSelected: true
        };
      }

      return {
        ...state,
        areStudentsSelected: false
      };
    case SET_ALL_STUDENTS_ARE_SELECTED:
      return {
        ...state,
        areStudentsSelected: action.areAllStudentsSelected
      };
    case CHECK_IF_ALL_TEACHERS_ARE_SELECTED:
      if (state.selectedTeacherIds.length === action.teacherIds.length) {
        return {
          ...state,
          areTeachersSelected: true
        };
      }

      return {
        ...state,
        areTeachersSelected: false
      };
    case SET_ALL_TEACHERS_ARE_SELECTED:
      return {
        ...state,
        areTeachersSelected: action.areAllTeachersSelected
      };
    case SET_SELECT_ALL_STUDENTS:
      if (!state.areStudentsSelected) {
        return {
          ...state,
          selectedStudentIds: [...new Set([...state.selectedStudentIds, ...action.studentIds])],
          areStudentsSelected: true
        };
      }
      return {
        ...state,
        areStudentsSelected: false,
        selectedStudentIds: state.selectedStudentIds.filter(id => !action.studentIds.includes(id))
      };
    case SET_SELECT_ALL_TEACHERS:
      cachedSelectedIds = [...state.selectedTeacherIds];
      if (action.teacherIds.some(id => state.selectedTeacherIds.indexOf(id) < 0)) {
        action.teacherIds.forEach(teacherId => {
          if (cachedSelectedIds.indexOf(teacherId) < 0) cachedSelectedIds.push(teacherId);
        });
      } else {
        cachedSelectedIds = cachedSelectedIds.filter(teacherId => action.teacherIds.indexOf(teacherId) < 0);
      }
      return {
        ...state,
        areTeachersSelected: false,
        selectedTeacherIds: cachedSelectedIds
      };
    // case SUBMIT_CLASSROOM_SEARCH:
    default:
      return state;
  }
}

// Current org may come from the url or the identity:
export const setActiveOrgId = orgId => ({
  type: ORG_DETAILS_LOADING,
  orgId
});
export const orgDetailsLoadError = orgId => ({
  type: ORG_DETAILS_LOAD_ERROR,
  orgId
});
export const orgDetailsLoaded = (orgId, orgData) => ({
  type: ORG_DETAILS_LOADED,
  orgId,
  orgData
});

export const clearArchivedClassesSelection = (orgId, classroomIds) => ({
  type: CLEAR_ARCHIVED_CLASSES_SELECTION,
  orgId,
  classroomIds
});

// Not applicable while multiple org-selection is out of scope:
// export const clearArchivedOrgsSelection = failedIds => ({
//   type: CLEAR_ARCHIVED_ORGS_SELECTION,
//   failedIds
// });

// Update list selection actions
export const setStudentSelected = (studentId, isSelected) => ({
  type: SET_STUDENT_SELECTED,
  studentId,
  isSelected
});
export const setTeacherSelected = (teacherId, isSelected) => ({
  type: SET_TEACHER_SELECTED,
  teacherId,
  isSelected
});
export const setClassroomSelected = (classroomId, isSelected) => ({
  type: SET_CLASSROOM_SELECTED,
  classroomId,
  isSelected
});

export const checkIfAllStudentsAreSelected = studentIds => ({
  type: CHECK_IF_ALL_STUDENTS_ARE_SELECTED,
  studentIds
});

export const setAllStudentsSelected = areAllStudentsSelected => ({
  type: SET_ALL_STUDENTS_ARE_SELECTED,
  areAllStudentsSelected
});

export const checkIfAllTeachersAreSelected = teacherIds => ({
  type: CHECK_IF_ALL_TEACHERS_ARE_SELECTED,
  teacherIds
});

export const setAllTeachersSelected = areAllTeachersSelected => ({
  type: SET_ALL_TEACHERS_ARE_SELECTED,
  areAllTeachersSelected
});

export const clearSelectedUser = () => ({
  type: CLEAR_USER_SELECTION
});

export const orgLoaded = orgId => ({
  type: ORG_LOADED,
  orgId
});

export const showClosePanelModal = showModal => ({
  type: SHOW_MODAL,
  showModal
});

export const setSelectAllStudents = (studentIds, areSelected) => ({
  type: SET_SELECT_ALL_STUDENTS,
  studentIds,
  areSelected
});

export const setSelectAllTeachers = (teacherIds, areSelected) => ({
  type: SET_SELECT_ALL_TEACHERS,
  teacherIds,
  areSelected
});
